<template>
  <div id="account-setting-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="inner-acc-setting">
            <div class="nav">
              <div class="xwrap">
                <a
                  href="#"
                  :class="{ current: tab == 'detail' }"
                  @click="tab = 'detail'"
                >
                  {{ $t("dashboard.Account.Section1.Title") }}
                </a>
                <a
                  href="#"
                  :class="{ current: tab == 'password' }"
                  @click="tab = 'password'"
                >
                  {{ $t("dashboard.Account.Section2.Title") }}
                </a>
                <a href="/404" :class="{ current: tab == 'notification' }">
                  {{ $t("dashboard.Account.Section3.Title") }}
                </a>
                <a
                  href="#"
                  :class="{ current: tab == 'delete' }"
                  @click="tab = 'delete'"
                >
                  {{ $t("dashboard.Account.Section4.Title") }}
                </a>
              </div>
            </div>
            <!-- end of nav -->
            <div class="acc-setting-detail" v-show="tab == 'detail'">
              <form @submit.prevent="updatePersonalInfo">
                <div class="change-pp">
                  <figure v-if="upload.fileUrl">
                    <img :src="upload.fileUrl" width="116" />
                  </figure>
                  <figure v-else>
                    <img src="/img/dummy/default-profile.png" width="116" />
                  </figure>
                  <div class="caption">
                    <h2>{{ personal.user.fullName }}</h2>
                    <div class="upload">
                      <input
                        type="file"
                        name="profile-pic"
                        id="profilePic"
                        ref="profilePic"
                        accept="images/*"
                        @change="preview"
                      />
                      <span class="cvr-bg-bf">Change Profile Picture</span>
                    </div>
                    <small>
                      File size: maximum 2 Megabytes. Allowed file extensions:
                      .JPG .JPEG .PNG
                    </small>
                  </div>
                  <!-- end of caption -->
                </div>
                <!-- end of change pp -->
                <h3>Personal Info</h3>
                <div class="emul">
                  <div class="gap">
                    <fieldset>
                      <label>First Name <sup>*</sup></label>
                      <input
                        type="text"
                        v-model="personal.user.firstName"
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label>Last Name <sup>*</sup></label>
                      <input
                        type="text"
                        v-model="personal.user.lastName"
                        required
                      />
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="gap">
                    <fieldset>
                      <label>Date of Birth</label>
                      <date-picker
                        v-model="personal.user.dateOfBirth"
                        :default-value="defaultDate"
                        :value-type="'format'"
                        format="DD MMMM YYYY"
                      ></date-picker>
                    </fieldset>
                    <fieldset>
                      <label>City of Residence</label>
                      <input type="text" v-model="personal.user.city" />
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="gap">
                    <fieldset>
                      <label>Email <sup>*</sup></label>
                      <input
                        type="email"
                        v-model="personal.email"
                        placeholder="johndoe@gmail.com"
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label>Phone Number <sup>*</sup></label>
                      <input
                        type="text"
                        v-model="personal.user.mobileNumber"
                        placeholder="+62 812 789 4567"
                        required
                      />
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <fieldset>
                    <label>Address</label>
                    <input type="text" v-model="personal.user.address" />
                  </fieldset>
                  <div class="verify" v-if="!personal.isVerified">
                    <p>For security reasons please verify your email address</p>
                    <a href="#" class="button" @click="verifyEmail">
                      Verify Email
                    </a>
                  </div>
                  <div class="centered">
                    <button type="submit" class="button">Save Changes</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of acc detail -->
            <div class="acc-setting-password" v-show="tab == 'password'">
              <form @submit.prevent="updatePassword">
                <h3>Password</h3>
                <div class="emul">
                  <div class="gap">
                    <fieldset>
                      <label>Old Password</label>
                      <div class="field-pass">
                        <input
                          type="password"
                          name="password"
                          required="required"
                          v-model="password.old"
                        />
                        <b></b>
                      </div>
                    </fieldset>
                    <fieldset>
                      &nbsp;
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="gap">
                    <fieldset>
                      <label>New Password</label>
                      <div class="field-pass">
                        <input
                          type="password"
                          name="new_password"
                          required="required"
                          v-model="password.new"
                        />
                        <b></b>
                      </div>
                    </fieldset>
                    <fieldset>
                      <label>Retype New Password</label>
                      <div class="field-pass">
                        <input
                          type="password"
                          name="retype_password"
                          required="required"
                          v-model="password.confirm"
                        />
                        <b></b>
                      </div>
                    </fieldset>
                  </div>
                  <!-- end of gap -->
                  <div class="centered">
                    <br /><br />
                    <button type="submit" class="button">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of acc password -->
            <div class="acc-setting-notif" v-show="tab == 'notification'">
              <form @submit.prevent="updateSubscription">
                <h3>Notification</h3>
                <div class="emul">
                  <div class="item">
                    <strong>How often would you like to be followed up?</strong>
                    <div class="field-radio">
                      <label>
                        <input
                          type="radio"
                          name="period"
                          value="Daily"
                          checked="checked"
                        />
                        <span>Daily</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="period"
                          value="Every 3 Days"
                        />
                        <span>Every 3 Days</span>
                      </label>
                      <label>
                        <input type="radio" name="period" value="Weekly" />
                        <span>Weekly</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="period"
                          value="Every 2 Weeks"
                        />
                        <span>Every 2 Weeks</span>
                      </label>
                    </div>
                    <!-- end of field radio -->
                  </div>
                  <div class="item">
                    <strong>
                      What would you like to recieve in your email?
                    </strong>
                    <div class="field-check">
                      <template v-for="(topic, index) in topics">
                        <label :key="index">
                          <input
                            :id="`topic-${topic.id}`"
                            type="checkbox"
                            name="topic[]"
                            value="Property of the Week"
                            v-model="topic.subscriptions"
                          />
                          <span>{{ topic.name }}</span>
                        </label>
                      </template>
                    </div>
                    <!-- end of field radio -->
                    <div class="centered">
                      <br /><br />
                      <button type="submit" class="button">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- end of acc notification -->
            <div class="acc-setting-delete" v-show="tab == 'delete'">
              <form @submit.prevent="deleteAccount">
                <h3>Delete My Account</h3>
                <p>We’re sorry to hear you’d like to delete your account.</p>
                <table>
                  <tr>
                    <td>Why are you deleting your account?</td>
                    <td>
                      <div class="fselect">
                        <span>{{ reason }}</span>
                        <ul>
                          <template v-for="(item, index) in reasonList">
                            <template v-if="item !== reason">
                              <li :key="index">
                                <a href="#" @click="reason = item">
                                  {{ item }}
                                </a>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="reason == 'Other'">
                    <td colspan="2">
                      <fieldset>
                        <input
                          type="text"
                          v-model="reasonUser"
                          placeholder="write your reason here..."
                          required
                        />
                      </fieldset>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To continue, please re-enter your password
                      <a
                        href="javascript:"
                        data-src="#forgot-pass"
                        data-fancybox
                      >
                        Forgot your password?
                      </a>
                    </td>
                    <td>
                      <div class="field-pass">
                        <input
                          type="password"
                          name="password"
                          required="required"
                          v-model="passwordToDelete"
                        />
                        <b></b>
                      </div>
                    </td>
                  </tr>
                </table>

                <p>
                  When you press the button below, all your data will be
                  permanently removed and will not be recoverable
                </p>
                <button type="submit" class="button">
                  Permanently delete my account
                </button>
              </form>
            </div>
            <!-- end of acc detail -->
          </div>
          <!-- end of inner accsetting -->
        </article>
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";
import * as moment from "moment";
import cookie from "js-cookie";
import $ from "jquery";

export default {
  name: "AccountSetting",
  metaInfo: {
    title: "Account Settings"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      tab: "detail",
      personal: this.$store.state.userdata.account,
      upload: {
        filename: null,
        file: null,
        fileUrl: null
      },
      password: {
        old: null,
        new: null,
        confirm: null
      },
      topics: [],
      reason: "I am no longer using my account",
      reasonList: [
        "I am no longer using my account",
        "I cannot find what I’m looking for in this platform",
        "I am disturbed by the follow-up and emails",
        "Other"
      ],
      reasonUser: null,
      passwordToDelete: null
    };
  },
  watch: {
    reason(newVal) {
      if (newVal !== "Other") {
        this.reasonUser = null;
      }
    }
  },
  computed: {
    defaultDate() {
      if (this.personal.user.dateOfBirth !== null) {
        return moment(this.personal.user.dateOfBirth).format("DD MMMM YYYY");
      } else {
        return "20 Mei 2000";
      }
    }
  },
  mounted() {
    this.getProfile();
    this.getNotificationSubscription();
    $(".inner-acc-setting .field-pass b").on("click", function() {
      var self = $(this),
        target = self.closest(".field-pass").find("input");

      if (target.attr("type") == "password") {
        target.attr("type", "text");
        self.addClass("merem");
      } else {
        target.attr("type", "password");
        self.removeClass("merem");
      }
    });
  },
  methods: {
    getProfile() {
      this.$axios.get(`/auth/me`).then(response => {
        this.personal = response.data.data;
        if (this.personal.user.dateOfBirth !== null) {
          this.personal.user.dateOfBirth = moment(
            this.personal.user.dateOfBirth
          ).format("DD MMM YYYY");
        }
        if (this.personal.user.avatar) {
          this.upload.fileUrl = this.personal.user.avatar;
        }
      });
    },
    updatePersonalInfo() {
      const form = new FormData();
      form.append("firstName", this.personal.user.firstName);
      form.append("lastName", this.personal.user.lastName);
      form.append("email", this.personal.email);
      form.append("mobileNumber", this.personal.user.mobileNumber);
      form.append(
        "dateOfBirth",
        moment(this.personal.user.dateOfBirth).format("YYYY-MM-DD")
      );
      form.append("city", this.personal.user.city);
      form.append("address", this.personal.user.address);
      if (this.upload.file) {
        form.append("avatar", this.upload.file);
      }

      this.$axios
        .put(`/accounts/personal-info`, form)
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          const userdata = {
            accessToken: this.$store.state.userdata.accessToken,
            refreshToken: this.$store.state.userdata.refreshToken,
            account: response.data.data
          };
          this.$store.commit("SET_USERDATA", userdata);
          cookie.set("userdata", userdata, { expires: 1 });
        })
        .catch(err => {
          this.$swal({
            icon: "error",
            text: err.response.data.message
          });
        });
    },
    preview(event) {
      if (event.target.files[0] !== undefined) {
        let extension = event.target.files[0].name.split(".").pop();
        let allowedExtention = ["jpg", "jpeg", "png"];
        if (allowedExtention.includes(extension.toLowerCase())) {
          const fileSize = Math.round(event.target.files[0].size / 1024);
          if (fileSize <= 2048) {
            this.upload.filename = event.target.files[0].name;
            this.upload.file = event.target.files[0];
            this.upload.fileUrl = URL.createObjectURL(event.target.files[0]);
          } else {
            this.$swal({
              icon: "error",
              text: "file is too large"
            });
          }
        } else {
          this.$swal({
            icon: "error",
            text: "not allowed file extension"
          });
        }
      }
    },
    clearAvatar() {
      this.upload.filename = null;
      this.upload.file = null;
      this.upload.fileUrl = null;
      document.getElementById("profilePic").value = null;
    },
    verifyEmail() {
      this.$axios
        .post(`/accounts/request-verify-email`, {
          email: this.personal.email
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        });
    },
    updatePassword() {
      if (this.password.new === this.password.confirm) {
        this.$axios
          .put(`/accounts/password`, {
            oldPassword: this.password.old,
            newPassword: this.password.new
          })
          .then(response => {
            this.$swal({
              icon: "success",
              text: response.data.message
            });
            this.password = {
              old: null,
              new: null,
              confirm: null
            };
          })
          .catch(error => {
            this.$swal({
              icon: "error",
              text: error.response.data.message
            });
          });
      } else {
        this.$swal({
          icon: "error",
          text: "password and confirmation password are not the same"
        });
      }
    },
    getNotificationSubscription() {
      this.$axios.get("/misc/notification-topics").then(resTopic => {
        this.$axios
          .get("/accounts/notifications/subscriptions")
          .then(resSub => {
            const mySubscription = resSub.data.data;
            const maped = resTopic.data.data;
            maped.map(topic => {
              const isSubscription = mySubscription.findIndex(
                sub => sub.id == topic.id
              );
              return Object.assign(topic, {
                subscriptions: isSubscription >= 0 ? true : false
              });
            });
            this.topics = maped;
          });
      });
    },
    updateSubscription() {
      const selectedTopic = this.topics
        .filter(topic => topic.subscriptions === true)
        .map(topic => topic.id);

      this.$axios
        .post("/accounts/notifications/subscriptions", {
          notificationTopicIds: selectedTopic
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        });
    },
    deleteAccount() {
      if (this.reason == "") {
        this.$swal({
          icon: "error",
          text: "please choose your reason"
        });
        return false;
      }
      this.$axios
        .delete("/accounts", {
          data: {
            reason: this.reason !== "Other" ? this.reason : this.reasonUser,
            password: this.passwordToDelete
          }
        })
        .then(response => {
          cookie.remove("rubii");
          this.$store.commit("SET_USERDATA", null);
          window.location.reload();
          this.$swal({
            icon: "success",
            text: response.data.message
          });
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>
